var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContainerCard',{attrs:{"head":_vm.$t('register.form.title')}},[_c('ValidationObserver',{ref:"observer",staticClass:"registrationForm"},[_c('div',{staticClass:"step userData"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('register.form.step1')))]),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.companyName')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.name),expression:"newCompany.name"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.companyNamePh')},domProps:{"value":(_vm.newCompany.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "name", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.adress')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.street),expression:"newCompany.street"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.streetPh')},domProps:{"value":(_vm.newCompany.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "street", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.zip),expression:"newCompany.zip"}],attrs:{"type":"text","patern":"[0-9]{5}","placeholder":_vm.$t('register.form.zipPh')},domProps:{"value":(_vm.newCompany.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "zip", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.city),expression:"newCompany.city"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.cityPh')},domProps:{"value":(_vm.newCompany.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "city", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.country),expression:"newCompany.country"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.countryPh')},domProps:{"value":(_vm.newCompany.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "country", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.contactInfo')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.contactPerson),expression:"newCompany.contactPerson"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.contactInfoNamePh')},domProps:{"value":(_vm.newCompany.contactPerson)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "contactPerson", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.phone),expression:"newCompany.phone"}],attrs:{"type":"tel","placeholder":_vm.$t('register.form.contactInfoPhonePh')},domProps:{"value":(_vm.newCompany.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "phone", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.email),expression:"newCompany.email"}],attrs:{"type":"email","placeholder":_vm.$t('register.form.contactInfoMailPh')},domProps:{"value":(_vm.newCompany.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "email", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"upload"},[_c('ValidationProvider',{staticClass:"upload",attrs:{"rules":"required","name":"verificationFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('register.form.upload')))]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.verificationFile),expression:"verificationFile"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.verificationFile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.verificationFile=$event.target.value}}})]}}])}),_c('input',{ref:"fileInput",attrs:{"type":"file","accept":"application/pdf, image/*"},on:{"change":function($event){return _vm.selectedFile($event)}}}),_c('p',[_vm._v(_vm._s(_vm.$t('register.form.or')))]),_c('p',[_c('a',{staticClass:"photo",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.takePicture = true}}},[_vm._v("📷 "+_vm._s(_vm.$t('register.form.photo' ))+" ")]),(_vm.cameraPicture)?_c('span',[_vm._v(_vm._s(_vm.$t('register.form.file'))+" ✅")]):_vm._e()]),(_vm.takePicture)?_c('v-easy-camera',{attrs:{"fullscreen":"","mustApprove":"","output":"blob","autoplay":""},on:{"close":function($event){_vm.cameraPicture = null; _vm.takePicture = false},"approve":function($event){_vm.takePicture = false; _vm.file = null; _vm.$refs.fileInput.value = null;}},model:{value:(_vm.cameraPicture),callback:function ($$v) {_vm.cameraPicture=$$v},expression:"cameraPicture"}}):_vm._e()],1)],1),_c('div',{staticClass:"step paymentInfo"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('register.form.step2')))]),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.recipient')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.recipient),expression:"newCompany.recipient"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.recipientPh')},domProps:{"value":(_vm.newCompany.recipient)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "recipient", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.iban')))]),_c('ValidationProvider',{attrs:{"rules":"required|iban"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.iban),expression:"newCompany.iban"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.ibanPh')},domProps:{"value":(_vm.newCompany.iban)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "iban", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.bic')))]),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCompany.bic),expression:"newCompany.bic"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.bicPh')},domProps:{"value":(_vm.newCompany.bic)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newCompany, "bic", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"terms"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.termsChecked),expression:"termsChecked"}],attrs:{"id":"agreed","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.termsChecked)?_vm._i(_vm.termsChecked,null)>-1:(_vm.termsChecked)},on:{"change":function($event){var $$a=_vm.termsChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.termsChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.termsChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.termsChecked=$$c}}}}),_c('label',{attrs:{"for":"agreed"}})]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('register.form.agbAndPrivacy.prefix'))+" ")]),_c('router-link',{attrs:{"to":{ name: 'legal-agb' },"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('register.form.agbAndPrivacy.agb')))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('register.form.agbAndPrivacy.middle'))+" ")]),_c('router-link',{attrs:{"to":{ name: 'legal-privacy' },"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('register.form.agbAndPrivacy.privacy')))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('register.form.agbAndPrivacy.suffix')))])],1)]),_c('button',{staticClass:"submit",attrs:{"disabled":!_vm.termsChecked},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('register.form.submit')))]),_c('div',{staticClass:"info"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.info.strong')))])]),_c('span',[_vm._v(_vm._s(_vm.$t('register.form.info.text')))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }